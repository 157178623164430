<template>
  <NotificationForTopPage />
    <div class="shadow-sm" id="nav">
      <header>
        <nav class="navbar navbar-expand-md">
          <div class="container-fluid">
            <router-link :to="{path:'/'}" class="navbar-brand" href="#"><img src="../../assets/images/lengedu.jpg" style="width: 150px;"></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
              <ul class="navbar-nav me-auto mb-2 mb-md-0">
                <li class="nav-item">
                  <router-link to="/mypage" class="nav-link"><i class="bi bi-calendar3"></i> Ders Programı</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/excercises" class="nav-link " href="javascript:;"><i class="bi bi-stopwatch"></i> Egzersizler</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/tests" class="nav-link " href="javascript:;"><i class="bi bi-card-checklist"></i> Testler</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/other-tools" class="nav-link " href="javascript:;"><i class="bi bi-plus-circle"></i> Yardımcı Araçlar</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/invite" class="nav-link " href="javascript:;"><i class="bi bi-share"></i> Arkadaşını Davet Et</router-link>
                </li>
              </ul>

              <div class="d-flex justify-content-end">
                <div class="dropdown">
                    <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="../../assets/images/profile/default.png" alt="mdo" width="32" height="32" class="rounded-circle bg-light">
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end text-small shadow">
                      <li><a class="dropdown-item" href="javascript:;" @click="OpenCloseMyProfileModal(true)">Profilim</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="javascript:;" @click="logout()">Çıkış Yap</a></li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
    <MyProfile :Visible="myProfileModalVisible" @CloseModalMyProfile="OpenCloseMyProfileModal($event)"/>
</template>
  <script>
  import MyProfile from "@/components/Modals/MyProfileModal.vue";
  import NotificationForTopPage from "@/components/Shared/NotificationForTopPage.vue";
  import store from "@/store";
  export default {
    name: "NavBar",
    data(){
      return{
        myProfileModalVisible:false
      }
    },
    components: {
      MyProfile,
      NotificationForTopPage
    },
    computed: {
      isLoggedIn: function() {
        return this.$store.getters.isAuthenticated;
      },
    },
    methods: {
      OpenCloseMyProfileModal(visible){
        this.myProfileModalVisible=visible;

      },
      async logout() {
        await this.$store.dispatch("ClearProfile");
        await this.$store.dispatch("LogOut");
        this.$router.push("/login");
      },
    },
    mounted:function(){
      let nameSurname = store.getters['NameSurname'];
      window.metrica(97130115, "params", {nameSurname:nameSurname});
    }
  };
  </script>
  
  <style>
    #nav{background-color: #FFF;}
    .text-small {
      font-size: 85%;
    }

    .dropdown-toggle:not(:focus) {
      outline: 0;
    }
  </style>