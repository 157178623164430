import { service } from "@/common/axios-service";
const state = {
}

const getters={
}

const actions ={
  async GetOperatorInfo() {
    return new Promise((resolve,reject)=>{
      service.post("StudentDashboard/GetOperatorInfo",{})
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async GetTrainingFileInfo() {
    return new Promise((resolve,reject)=>{
      service.post("StudentDashboard/GetTrainingFileInfo",{})
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async GetLearnChartInfo() {
    return new Promise((resolve,reject)=>{
      service.post("StudentDashboard/GetLearnChartInfo",{})
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
}

const mutations ={
}

export default{
    state,
    getters,
    actions,
    mutations
};