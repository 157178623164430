<template>
  <div class="card rounded-4 mb-4 border-0" v-if="chartVisible">
    <div class="card-body">
      <div v-if="loading" class="text-center">
        <div class="spinner-border">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div>
        <div>
          <div class="progress" style="margin-top: 45px;" role="progressBar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
            <span class="animal" :style="{'left': animeBar+'%'}"><img src="../../assets/images/home/lengedu-logo.png" /></span>
            <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{'width': progressBar +'%'}"></div>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between mb-3">
          <div class="p-2"><i class="bi bi-flag"></i></div>
          <div class="p-2">A1</div>
          <div class="p-2">A2</div>
          <div class="p-2">B1</div>
          <div class="p-2">B2</div>
          <div class="p-2">C1</div>
          <div class="p-2">C2</div>
          <div class="p-2"><i class="bi bi-flag-fill"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { ErrorToast } from "@/common/notification.js";
  export default {
    name: "LearningChart",
    props: {
    },
    data() {
      return {
        chartVisible:true,
        loading:false,
        maxNumber:0,
        learnNumber:0,
        progressBar:0,
        animeBar:0
      }
    },
    computed: {
    },
    methods: {
      ...mapActions(["GetLearnChartInfo"]),
      calculateBarWight(){
          let rate = (100*this.learnNumber)/this.maxNumber;
          this.progressBar=rate;
          if(rate<=4){
            this.animeBar=0;
          }else if(rate>4 && rate<98){
            let ext = 1;
            if(rate>9) ext = 1 + (rate/20);
            this.animeBar=rate-parseInt(ext);
          }else{
            this.animeBar=90;
          }
      },
      async getLearnChartInfo(){
          this.loading=true;
          //let toastId = LoadingToast();
          await this.GetLearnChartInfo()
          .then((res)=>{
            this.chartVisible=res.isVisibleChart;
            this.maxNumber=res.maxNumber;
            this.learnNumber=res.learnNumber;
            this.calculateBarWight();
            this.loading=false;
            //UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
          })
          .catch((err)=>{
            this.loading=false;
            if(err.message){
              ErrorToast('getLearnChartInfo' + err.message);
            }
          });
        }
    },
    watch: {
    },
    mounted: function() {
      this.getLearnChartInfo();
    }
  };
  </script>
  
  <style>
  .progress-bar {
      background-color: #4fb748 !important;
  }
  .animal {
    position: absolute;
    display: block;
    top: 15px;
    z-index: 1;
  }
  .animal img{
    width: 40px;
  }
  </style>