<template>
<div>
    <div v-if="isLoading" class="text-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <ul class="nav nav-tabs justify-content-end">
            <li class="nav-item active" v-if="IsContent(1)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(1)">Bölüm 1</a>
            </li>
            <li class="nav-item" v-if="IsContent(2)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(2)">Bölüm 2</a>
            </li>
            <li class="nav-item" v-if="IsContent(3)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(3)">Bölüm 3</a>
            </li>
            <li class="nav-item" v-if="IsContent(6)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(6)">Bölüm 4</a>
            </li>
            <li class="nav-item active" v-if="IsContent(10)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(10)">Diyalog 1</a>
            </li>
            <li class="nav-item" v-if="IsContent(11)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(11)">Diyalog 2</a>
            </li>
            <li class="nav-item" v-if="IsContent(12)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(12)">Diyalog 3</a>
            </li>
            <!-- <li class="nav-item" v-if="IsContent(13)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(13)">Okuma</a>
            </li> -->
        </ul>
        <div class="tab-content p-2">
            <div class="tab-pane fade show active" v-if="bookContent!=null">
                <a v-if="bookContent.bookUrl!=null" v-bind:href="bookContent.bookUrl" target="_blank"><img :src="bookContent.bookUrl" class="img-fluid"></a>
                <div class="mt-2 p-3" v-if="bookContent.contentHtml!=null">
                    <h3 v-if="bookContent.contentTitle!=null">{{ bookContent.contentTitle }}</h3>
                    <div class="dialog" v-html="bookContent.contentHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div> 
</template>
<script>
  export default {
    name: "TabLearnSubject",
    props: {
      contents:{
        type:Array
      }
    },
    data() {
      return {
        bookContent:null,
        isLoading:true,
      }
    },
    computed: {
       
    },
    methods: {
        IsContent(value){
            if(this.contents.length>0)
            {
                let find = this.contents.find(x=>x.contentType===value);
                if(find){
                    return true;
                }
            }
            return false;
        },
        GetContent(contentType){
            let contentHtml = '',contentTitle='',contentImageUrl='';
            if(this.contents.length){
                let findBookContent = this.contents.filter(x=>x.contentType==contentType)[0];
                if(findBookContent){
                    contentHtml = this.RenderDialog(findBookContent.contentHtml);
                    contentTitle = findBookContent.contentTitle;
                    contentImageUrl = findBookContent.fileName==null ? null : process.env.VUE_APP_ROOT_API+'Book/BookImage?fileName=' + findBookContent.fileName;
                }
            }

            this.bookContent = {
                bookUrl: contentImageUrl,
                contentHtml : contentHtml,
                contentTitle: contentTitle,
            }
        },
        RenderDialog(value){
            let names = [
                {key:'Emma',class:'green-text'},{key:'Jack',class:'red-text'},
                {key:'Alice',class:'green-text'},{key:'Bob',class:'red-text'},
                {key:'Lisa',class:'green-text'},{key:'Tom',class:'red-text'},
                {key:'Mike',class:'green-text'},{key:'Sarah',class:'red-text'},
                {key:'John',class:'red-text'}
            ];
            let text = value;
            if(text){
                let name='',colorClass='';
                for (let i = 0; i < names.length; i++) {
                name = names[i].key + ':';
                colorClass = names[i].class
                text = text.replace(new RegExp(name,'g'),'<br><span class=\''+colorClass+'\'>'+name+'</span>');
                }
            }
            return text;
        }
    },
    mounted: function() {
      
    },
    watch:{
        contents(value){
            if(value){
                this.isLoading=false;
                if(this.IsContent(1)){
                this.GetContent(1);
                }else if(this.IsContent(10)){
                this.GetContent(10);
                }
            }
        }
    }
  };
  </script>
    
<style scoped>   
.dialog ::v-deep .red-text { 
    color: #faa125;
    font-weight:bold;
}
.dialog ::v-deep .green-text { 
    color: #52b748;
    font-weight:bold;
}
</style>