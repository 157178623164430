<template>
  <div class="card rounded-4 mb-4 border-0">
    <div class="card-body">
      <h5 class="card-title mb-3">Lengedu İngilizce Öğrenmenin En Kolay Yolu</h5>
      <hr class="border opacity-25">
      <div v-if="loading" class="text-center">
        <div class="spinner-border">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div v-if="lessonDescription!=null">
          <div class="row align-items-start">
            <div class="col-lg-2 text-center text-lg-start">
              <img src="../../assets/images/home/today-lesson.svg" style="width: 120px;">
            </div>
            <div class="col text-center text-lg-start">
              <div class="mt-3">
                <span class="fw-bold text-capitalize">{{ lessonDescription.title }}</span><br>
                <small class="text-capitalize">{{ lessonDescription.description }}</small>
              </div>
            </div>
            <div class="col-lg-2 text-center text-lg-end">
              <router-link v-if="lessonDescription.reviewButtonVisible && LessonUrl!=null" :to="{path:'/courses/'+LessonUrl}"  class="btn btn-sm btn-primary mt-3">İncele <i class="bi bi-arrow-right-short"></i></router-link>
            </div>
          </div>
          <hr class="border opacity-25">
        </div>
        <div v-if="packageDescription!=null">
          <div class="row align-items-start">
            <div class="col-lg-2 text-center text-lg-start">
              <img src="../../assets/images/home/package-right.svg" style="width: 120px;">
            </div>
            <div class="col text-center text-lg-start">
              <div class="mt-3">
                <span class="fw-bold">{{ packageDescription.title }}</span><br>
                <small>{{ packageDescription.description }}</small>
              </div>
            </div>
            <div class="col-lg-2 text-center text-lg-end">
              <router-link v-if="packageDescription.reviewButtonVisible" :to="{path:'/training'}" class="btn btn-sm btn-primary mt-3">İncele <i class="bi bi-arrow-right-short"></i></router-link>
            </div>
          </div>
          <hr class="border opacity-25">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import{CleanUrl} from '@/common/url-helper';
  import { ErrorToast} from "@/common/notification.js";
  export default {
    name: "TrainingInfo",
    props: {
    },
    data() {
      return {
        loading:false,
        packageDescription:'',
        lessonDescription:'',
        bookName:'',
        bookId:''
      }
    },
    computed: {
      LessonUrl(){
        if(this.bookId!=null && this.bookName!=null && this.bookId!='' &&  this.bookName!=''){
          return CleanUrl(this.bookName)+'/'+this.bookId;
        }
        return null;
      }
    },
    methods: {
      ...mapActions(["GetTrainingFileInfo"]),
      async getTrainingFileInfo(){
        this.loading=true;
        //let toastId = LoadingToast();
        await this.GetTrainingFileInfo()
        .then((res)=>{
          this.loading=false;
          this.bookName = res.bookName;
          this.bookId = res.bookId;
          this.packageDescription = res.packageDescription;
          this.lessonDescription=res.lessonDescription;
          this.$emit("DailyLessonBookUrl",this.LessonUrl);
          //UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
        })
        .catch((err)=>{
          this.loading=false;
          if(err.message){
            ErrorToast('getTrainingFileInfo' + err.message);
          }
        });
      }
    },
    watch: {
      
    },
    mounted: function() {
      this.getTrainingFileInfo();
    }
  };
  </script>
  
  <style>
  </style>