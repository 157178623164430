<template>
  <div class="container">
    <footer class="border-top mt-3 p-2">
      <VersionNumber />
    </footer>
  </div>
</template>
<script>
import { CampaingToast } from "@/common/notification.js";
import VersionNumber from "@/components/Shared/VersionNumber.vue";
export default {
  name: "FooterInPage",
  components: {
      VersionNumber
  },
  computed: {
  },
  methods: {
    ShowCampaing(){
      //let msg = "<span style=\"font-size:13px; font-weight:bold; color:#4fb748;\">Arkadaşlarını davet etmenin tam zamanı!</span><br><a style=\"text-decoration: none; color:#888; font-size:13px;\" href=\"/invite\">Nisan'a özel kayıt olan tüm arkadaşlarınıza ve size %10 İndirim + 10 Ders Hediye !!!</a>"
      let msg = "<span style=\"font-size:13px; font-weight:bold; color:#4fb748;\">Arkadaşlarını davet etmenin tam zamanı!</span><br><span style=\"text-decoration: none; color:#888; font-size:13px;\">Nisan'a özel kayıt olan tüm arkadaşlarınıza ve size %10 İndirim + 10 Ders Hediye !!!</span><br><a href=\"/invite\" class=\"btn btn-primary btn-sm mt-2\">Hemen Davet Et</a>"
      CampaingToast(msg);
    },
    GetCampaingDate(){
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setMinutes(date1.getMinutes()+30);
      return date2;
    },
    CheckShow(){
      if(localStorage.inviteCampaingLastDate){
        let localDate = new Date(localStorage.inviteCampaingLastDate);
        let nowDate = new Date();
        if (localDate < nowDate){
          localStorage.inviteCampaingLastDate = this.GetCampaingDate();
          return true;
        }
        return false;
      }else{
        localStorage.inviteCampaingLastDate = this.GetCampaingDate();
        return true;
      }
    }
  },
  mounted:function(){
    let mainThis = this;
    let finihDate = new Date('2024-04-30 23:59:59');
    let nowDate = new Date();
    if (finihDate > nowDate && mainThis.CheckShow()) {
        setTimeout(function () { mainThis.ShowCampaing()}, 5000);
    }
  }
 
};
</script>
  
<style></style>