<template>
<div>
    <ul class="nav  nav-tabs justify-content-end">
        <li class="nav-item">
            <a class="nav-link text-dark active" href="#" data-bs-toggle="tab" data-bs-target="#AllWord">Kelime Listesi</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link text-dark" href="#" data-bs-toggle="tab" data-bs-target="#Exercise">Egzersiz</a>
        </li> -->
        </ul>
        <div class="tab-content p-2">
        <div class="tab-pane fade show active" id="AllWord" role="tabpanel">
            <div v-if="words!=null && words.length>0">
                <ul class="list-group">
                    <li class="list-group-item" v-for="(w,i) in words.filter(x=>x!=null)" :key="w">
                        <div class="float-start">
                            <span class="text-capitalize fs-5">{{i+1}}. {{w.word}}</span> <a v-if="w.audioFileName!=null" href=javascript:; @click="playAudio(w.audioFileName)"><i class="bi bi-volume-up-fill"></i></a> 
                        </div>
                        <div class="float-end text-capitalize">
                            <small>{{meaningParse(w.meaning)}}</small>
                        </div>
                        <div class="clearfix"></div>
                        <div class="fst-italic" v-html="exampleParse(w.example)"></div>
                        <!-- <a href="javascript:;" class="text-danger" title="Favori Kelime Listesine Ekle"><i class="bi bi-heart float-end"></i></a> -->
                    </li>
                </ul>
                <audio style="display: none;" controls ref="WordPlay" src=""></audio>
            </div>
            <div v-else class="text-center">
                Konuya ait kelime bulunamdı.
            </div>
        </div>
            <!-- <div class="tab-pane fade" id="Exercise" role="tabpanel">
                <div class="row">
                <div class="col-9">
                    <div class="progress mt-2" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" style="width: 50%"></div>
                    </div>
                </div>
                <div class="col-3">Doğru : 1 | Yanlış : 5</div>
                </div>
                <div class="text-center h3 mb-5 mt-3">BOOK</div>
                <div class="row justify-content-center">
                <div class="col-5 d-grid">
                    <button class="btn btn-outline-dark mb-3">Kitap</button>
                </div>
                <div class="col-5 d-grid">
                    <button class="btn btn-outline-dark mb-3">Defter</button>
                </div>
                <div class="col-5 d-grid">
                    <button class="btn btn-outline-dark mb-3">Araba</button>
                </div>
                <div class="col-5 d-grid">
                    <button class="btn btn-outline-dark mb-3">Seyehat</button>
                </div>
                </div>
            </div> -->
        </div>
</div> 
</template>
<script>
  export default {
    name: "TabLearnWord",
    props: {
      words:{
        type:Array
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
       playAudio(value){
            let url = process.env.VUE_APP_ROOT_API+"Source/dictionary/audio?word="+value;
            this.$refs.WordPlay.src=url;
            this.$refs.WordPlay.play();
       },
       meaningParse(value){
            let result ='';
            if(value){
                result = value.replace(/\|/gi,', ');
            }
            return result;
       },
       exampleParse(value){
            let result ='';
            if(value){
                result = '<small>'+value.replace(/\|/gi,'<br />')+'</small>';
            }
            return result;
       }
       
    },
    mounted: function() {
        
    }
  };
  </script>
    
  <style></style>