<template>
<div>
    <div v-if="isLoading" class="text-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div class="p-2">
            <div v-if="bookContent!=null">
                <div class="p-3" v-if="bookContent.contentHtml!=null">
                    <h3>Okuma</h3>
                    <div v-html="bookContent.contentHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div> 
</template>
<script>
  export default {
    name: "TabLearnReading",
    props: {
      contents:{
        type:Array
      }
    },
    data() {
      return {
        bookContent:null,
        isLoading:true,
      }
    },
    computed: {
       
    },
    methods: {
        GetContent(){
            let contentType = 13;
            let contentHtml = '',contentTitle='',contentImageUrl='';
            if(this.contents.length){
                let findBookContent = this.contents.filter(x=>x.contentType==contentType)[0];
                if(findBookContent){
                    contentHtml = findBookContent.contentHtml;
                }
            }

            this.bookContent = {
                bookUrl: contentImageUrl,
                contentHtml : contentHtml,
                contentTitle: contentTitle,
            }
        },
    },
    mounted: function() {
    },
    watch:{
        contents(value){
            if(value){
                this.isLoading=false;
                this.GetContent();
            }
        }
    }
  };
  </script>
    
<style scoped>   
</style>