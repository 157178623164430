<template>
  <div v-for="n in notifications" :key="n" class="alert alert-dismissible border-0 rounded-0 m-0" :class="n.typeClass" role="alert">
    <div class="container">
      <div v-html="n.content"></div>
      <button v-if="n.visibleClose" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>
</template>
  <script>
  export default {
    name: "NotificationForTopPage",
    data(){
      return{
        notifications:[
          //{
          // visibleClose:false,
          // typeClass:'alert-danger',
          // content:'Değerli öğrencilerimiz,<br>30 Ağustos Zafer Bayramı nedeniyle ders <u>işlenmeyecektir.</u> Dersler tarafımızdan ertelenecektir. <br />Zafer Bayramımız kutlu olsun.'
          //},
          // {
          //  visibleClose:false,
          //  typeClass:'alert-danger',
          //  content:'Değerli öğrencilerimiz,<br>15.07.2024 tarihi Demokrasi ve Millî Birlik Günü olduğundan ders işlenmeyecektir. Tatil sonrası ilk dersiniz 16.07.2024 Salı günü olacaktır. Dersler tarafımızca ertelenecektir.'
          // },
          //{
          //  visibleClose:false,
          //  typeClass:'alert-danger',
          //  content:'Değerli öğrencilerimiz,<br>17.06.2024 tarihinden 21.06.2024 tarihine kadar Bayram tatili olacaktır, tatil sonrası ilk dersiniz 24.06.2024 Pazartesi günü olacaktır. Dersleriniz tarafımızca ertelenecektir.<br>Lengedu Online İngilizce Eğitimi Ailesi olarak iyi bayramlar dileriz.'
          //},
          // {
          //   visibleClose:true,
          //   typeClass:'alert-warning',
          //   content:'<h4>Size Özel %20 İndirim</h4><div>Derslerinizi sıkı takip ettiğinizi görüyoruz. Bundan dolayı size bir teklifimiz var.</div>'
          // }
        ]
      }
    },
    components: {
    },
    computed: {
      
    },
    methods: {
    },
  };
  </script>
  
  <style>
    
  </style>